import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import SearchBar from './search-bar.component';
import { setSearchTermAction } from '../../common-reducers/filter-reducer/filter.reducer';
import { setCurrentCityAction } from '../../common-reducers/city-reducer/city.reducer';
import { getCoordinates } from '../../common-reducers/geolocation-reducer/geolocation.actions';
import { searchAvailable } from '../../common-reducers/room-types-reducer/room-types.action';
import { setRoomTypesAction } from '../../common-reducers/room-types-reducer/room-types.reducer';
import { setSelectedCityByName } from '../../common-reducers/city-reducer/city.actions';

function mapStateToProps({ accountReducer, cityReducer, filterReducer, geolocationReducer }) {
    return {
        isLoading: accountReducer.isLoading,
        cities: cityReducer.cities,
        selectedCity: cityReducer.selectedCity,
        ...filterReducer,
        coordinates: geolocationReducer.coordinates,
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setSearchTermAction,
            setCurrentCityAction,
            getCoordinates,
            searchAvailable,
            setRoomTypesAction,
            setSelectedCityByName,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
