import _ from 'lodash';

import { portfolioService, mediaService, eventService } from '../../services/homii-services';
import {
    setIsLoadingAction,
    setSelectedRoomPortfolioAction,
    setAllPortfoliosAction,
} from './portfolio.reducer';

export function getSelectedRoomPortfolioById(portfolioId) {
    return async (dispatch) => {
        dispatch(setIsLoadingAction(true));
        return portfolioService
            .getPortfolioById(portfolioId)
            .then((response) => {
                const portfolioWithImages = mediaService.generatePortfolioImageUrls(response);
                dispatch(setSelectedRoomPortfolioAction(portfolioWithImages));
            })
            .catch((error) => {
                throw Error(error.response.data.message);
            })
            .finally(() => {
                dispatch(setIsLoadingAction(false));
            });
    };
}

export function getAllPortfolios() {
    return async (dispatch) => {
        dispatch(setIsLoadingAction(true));
        return portfolioService
            .getAllPortfolios()
            .then((portfolios) => {
                let portfoliosWithImages = [];
                _.map(portfolios, (portfolio) => {
                    const portfolioWithImages = mediaService.generatePortfolioImageUrls(portfolio);
                    portfoliosWithImages.push(portfolioWithImages);
                });

                dispatch(setAllPortfoliosAction(portfoliosWithImages));
            })
            .catch((error) => {
                throw Error(error.response.data.message);
            })
            .finally(() => {
                dispatch(setIsLoadingAction(false));
            });
    };
}

export async function submitPortfolioInquiry(
    portfolioId,
    roomTypeId,
    firstName,
    lastName,
    email,
    phoneNumber,
    inquiry,
) {
    try {
        await portfolioService.submitPortfolioInquiry(
            portfolioId,
            roomTypeId,
            firstName,
            lastName,
            email,
            phoneNumber,
            inquiry,
        );
        eventService.logCustomEvent('successful_portfolio_inquiry', {
            description: 'Successfuly submitted a portfolio inquiry',
            firstName,
            lastName,
            email,
            phoneNumber,
            inquiry,
        });
    } catch (error) {
        throw Error(
            _.get(
                error,
                'response.data.message',
                'An error occurred while trying to submit your inquiry.',
            ),
        );
    }
}
