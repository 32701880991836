import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import styled, { withTheme } from 'styled-components';
import 'react-dates/initialize';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { boundMethod } from 'autobind-decorator';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

class HorizontalDateRangePicker extends Component {
    static propTypes = {
        startDate: momentPropTypes.momentObj,
        endDate: momentPropTypes.momentObj,
        dateFormat: PropTypes.string,
        offset: PropTypes.bool,
        inputWidth: PropTypes.number,
        isOutsideRange: PropTypes.bool,

        onDateRangeChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            startDate: props.startDate,
            endDate: props.endDate,
            focusedInput: null,
        };
    }

    @boundMethod
    onDatesChange(startDate, endDate) {
        const { onDateRangeChange } = this.props;
        if (onDateRangeChange) {
            onDateRangeChange(startDate, endDate);
        }
        this.setState({ startDate, endDate });
    }

    render() {
        const { dateFormat, offset, inputWidth, isOutsideRange } = this.props;
        return (
            <StyledDatePickerWrapper
                id="date_range_picker"
                $offset={offset}
                inputwidth={inputWidth}
            >
                <DateRangePicker
                    startDate={this.state.startDate}
                    startDatePlaceholderText="Start"
                    endDatePlaceholderText="End"
                    startDateId="start_date_id"
                    endDate={this.state.endDate}
                    endDateId="end_date_id"
                    onDatesChange={({ startDate, endDate }) =>
                        this.onDatesChange(startDate, endDate)
                    }
                    focusedInput={this.state.focusedInput}
                    onFocusChange={(focusedInput) => this.setState({ focusedInput })}
                    keepOpenOnDateSelect
                    hideKeyboardShortcutsPanel
                    displayFormat={dateFormat}
                    showDefaultInputIcon
                    enableOutsideDays={false}
                    isOutsideRange={isOutsideRange}
                    small
                    block
                    navPrev={<StyledChevronLeftRoundedIcon id="prev_arrow" fontSize="small" />}
                    navNext={<StyledChevronRightRoundedIcon id="next_arrow" fontSize="small" />}
                />
            </StyledDatePickerWrapper>
        );
    }
}

export default withTheme(HorizontalDateRangePicker);

const StyledChevronLeftRoundedIcon = styled(ChevronLeftRoundedIcon)`
    position: absolute;
    left: 37%;
    top: 22px;
    color: ${(props) => props.theme.calendarNav};
`;

const StyledChevronRightRoundedIcon = styled(ChevronRightRoundedIcon)`
    position: absolute;
    left: 42%;
    top: 22px;
    color: ${(props) => props.theme.calendarNav};
`;

const StyledDatePickerWrapper = styled.div`
    width: 90%;
    // Will edit everything selected including everything between a range of dates
    .CalendarDay {
        border: none;
        color: ${(props) => props.theme.calendarBlack};
    }
    .CalendarDay__blocked_out_of_range,
    .CalendarDay__blocked_out_of_range:active,
    .CalendarDay__blocked_out_of_range:hover {
        color: ${(props) => props.theme.calendarGrey};
    }

    .CalendarDay__selected_start {
        width: 34px;
        height: 34px;
        background: ${(props) => props.theme.primaryLight};
        border: 0px;
        padding: 0px;
        border-radius: 50%;
        position: relative;
    }

    .CalendarDay__selected_start::before {
        content: '';
        display: inline-block;
        background: ${(props) => props.theme.primaryLight};
        opacity: 0.5;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .CalendarDay__selected_end {
        width: 34px;
        height: 34px;
        background: ${(props) => props.theme.primaryLight};
        border: 0px;
        padding: 0px;
        border-radius: 50%;
        position: relative;
    }

    .CalendarDay__selected_end::before {
        content: '';
        display: inline-block;
        background: ${(props) => props.theme.primaryLight};
        opacity: 0.5;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
    }

    .CalendarDay__selected_span {
        background: ${(props) => props.theme.primaryLight};
        opacity: 0.5;
        color: ${(props) => props.theme.calendarBlack};
    }

    .CalendarDay__selected {
        color: white;
        opacity: 1;
    }

    .CalendarDay__selected:hover {
        background: ${(props) => props.theme.primaryLight};
        color: ${(props) => props.theme.calendarBlack};
    }

    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
        background: ${(props) => props.theme.primaryLight};
        opacity: 0.5;
        border: none;
    }

    .DateRangePickerInput__withBorder {
        border: none;
    }

    .DateInput {
        width: ${(props) => (props.inputwidth ? `${props.inputwidth}px` : '55px')};
        text-align: left;
    }

    .DateInput_input,
    .DateInput_input_1 {
        font-size: 15px;
        padding: 0;
        display: flex;

        font-style: normal;
        font-weight: normal;
        line-height: 24px;
    }

    .DateInput_input__focused,
    .DateInput_input__focused_2 {
        border-bottom: 2px solid ${(props) => props.theme.primaryLight};
    }

    .DateRangePickerInput_calendarIcon {
        margin: 0px;
        padding: 0px;
        outline: none;
        box-shadow: none;
        padding-right: 5px;
    }

    .DateRangePickerInput_calendarIcon_svg {
        fill: ${(props) => props.theme.primaryLight};
        height: 15px;
        width: 14px;
        vertical-align: middle;
        margin-bottom: 5px;
        margin-left: 5px;
    }

    .DateRangePickerInput_arrow {
        padding-right: 5px;
        color: ${(props) => props.theme.placeholderSearchText};
    }

    .DayPicker {
        right: ${({ $offset }) => ($offset ? '140px' : null)};
    }

    .DateRangePicker_picker {
        background: transparent;
    }
`;
