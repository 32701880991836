import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { boundMethod } from 'autobind-decorator';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Router from 'next/router';
import getConfig from 'next/config';

import Dropdown from '../common-components/searchbar-dropdown.component';
import HorizontalDateRangePicker from '../common-components/horizontal-date-range-picker.component';
import { eventService } from '../../services/homii-services';

const defaultCityText = 'Choose Location';
const defaultTermText = 'Monthly or Daily';
const defaultStyleText = 'Private or Shared';

const termOptions = [
    {
        value: 'Monthly',
        label: 'Monthly',
        subLabel: 'Browse for next month',
        id: 'monthly_select',
    },
    {
        value: 'Daily',
        label: 'Daily',
        subLabel: 'Choose your dates',
        id: 'daily_select',
    },
];

const styleOptionsEnabled = [
    {
        value: 'private_apartment',
        label: 'Private Apartment',
        subLabel: 'Entire apartment for yourself',
        id: 'private_apartment_select',
    },
    {
        value: 'private_bedroom',
        label: 'Private Bedroom',
        subLabel: 'Room in shared living space',
        id: 'private_bedroom_select',
        isDisabled: false,
    },
    {
        value: 'shared_male',
        label: 'Shared - Male Only',
        subLabel: 'Stay with a male HOMii',
        id: 'shared_select_male',
        isDisabled: false,
    },
    {
        value: 'shared_female',
        label: 'Shared - Female Only',
        subLabel: 'Stay with a female HOMii',
        id: 'shared_select_female',
        isDisabled: false,
    },
];

const styleOptionsDisabled = [
    {
        value: 'private_apartment',
        label: 'Private Apartment',
        subLabel: 'Entire apartment for yourself',
        id: 'private_apartment_select',
    },
    {
        value: 'private_bedroom',
        label: 'Private Bedroom',
        subLabel: 'Room in shared living space',
        id: 'private_bedroom_select',
        isDisabled: true,
    },
    {
        value: 'shared_male',
        label: 'Shared - Male Only',
        subLabel: 'Stay with a male HOMii',
        id: 'shared_select_male',
        isDisabled: true,
    },
    {
        value: 'shared_female',
        label: 'Shared - Female Only',
        subLabel: 'Stay with a female HOMii',
        id: 'shared_select_female',
        isDisabled: true,
    },
];

const { publicRuntimeConfig } = getConfig();

class SearchBar extends Component {
    static propTypes = {
        cities: PropTypes.object.isRequired,
        type: PropTypes.string,
        selectedCity: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        isHome: PropTypes.bool,
        coordinates: PropTypes.object,
        query: PropTypes.object,
        theme: PropTypes.object,
        portfolioId: PropTypes.string,

        setSearchTermAction: PropTypes.func,
        setCurrentCityAction: PropTypes.func,
        searchAvailable: PropTypes.func,
        getCoordinates: PropTypes.func,
        setRoomTypesAction: PropTypes.func,
        setSelectedCityByName: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            city: defaultCityText,
            style: defaultStyleText,
            term: defaultTermText,
            dateText: 'Select Dates',
            monthlyStartDate: moment().format('YYYY-MM-DD'),
            monthlyEndDate: moment().add(1, 'months').startOf('month').format('YYYY-MM-DD'),
            dailyStartDate: moment().format('YYYY-MM-DD'),
            dailyEndDate: moment().add(1, 'day').format('YYYY-MM-DD'),
            showDatePicker: false,
            styleOptions: styleOptionsEnabled,
            tempSelection: null,
            dateChanged: false,
            shouldUpdate: true,
        };
    }

    componentDidMount() {
        this.getQueryParams();
    }

    componentDidUpdate(prevProps) {
        const { coordinates, searchAvailable } = this.props;
        const { shouldUpdate } = this.state;
        if (prevProps.coordinates !== coordinates) {
            if (coordinates.latitude && coordinates.longitude && shouldUpdate) {
                searchAvailable('coordinates', coordinates.latitude, coordinates.longitude);
            }
        }
    }

    @boundMethod
    onCityChange(value) {
        const { setCurrentCityAction, getCoordinates } = this.props;
        if (value === 'Nearby') {
            getCoordinates();
            this.setState({ shouldUpdate: false });
        }
        this.setState({ city: value });
        setCurrentCityAction(value);
        this.onSearchClick(value);
    }

    @boundMethod
    onTermChange(value) {
        this.setState({ term: value });
        if (value === 'Monthly') {
            this.setState({
                dateText: `Booking from Today`,
                monthlyStartDate: moment(),
                showDatePicker: false,
                styleOptions: styleOptionsEnabled,
            });
        } else {
            this.setState({
                dateText: 'Select Dates',
                style: 'private_apartment',
                styleOptions: styleOptionsDisabled,
            });
        }
    }

    @boundMethod
    onStyleChange(value) {
        this.setState({ style: value });
    }

    @boundMethod
    onSearchClick(cityValue) {
        const { setSearchTermAction, query, type, portfolioId } = this.props;
        const { city, term, style } = this.state;

        const newQuery = { ...query };
        const pageParam = _.get(query, 'page');

        newQuery.city = cityValue ? cityValue : city;
        if (term !== defaultTermText) {
            newQuery.term = term;
        }
        if (style !== defaultStyleText) {
            newQuery.style = style;
        }
        if (pageParam) {
            delete newQuery.page;
        }
        if (type === 'Portfolio') {
            newQuery.portfolioId = portfolioId;
        }
        setSearchTermAction({ selectedCity: null, selectedTerm: term, selectedStyle: style });
        Router.push(
            {
                pathname: '/bookings',
                query: newQuery,
            },
            undefined,
            { shallow: false },
        );
    }

    @boundMethod
    async onFindaSpaceClick() {
        const {
            city,
            term,
            style,
            monthlyStartDate,
            monthlyEndDate,
            dailyStartDate,
            dailyEndDate,
        } = this.state;

        const {
            query,
            selectedCity,
            searchAvailable,
            coordinates,
            setRoomTypesAction,
            setSelectedCityByName,
        } = this.props;

        const buildingParam = _.get(query, 'building');
        const pageParam = _.get(query, 'page');
        let shallow = false;

        let startDate = moment(monthlyStartDate).format('YYYY-MM-DD');
        let endDate = moment(monthlyEndDate).format('YYYY-MM-DD');

        this.setState({
            tempSelection: { selectedTerm: term, selectedStyle: style },
            dateChanged: false,
        });

        if (term === 'Daily') {
            startDate = moment(dailyStartDate).format('YYYY-MM-DD');
            endDate = moment(dailyEndDate).format('YYYY-MM-DD');
        } else {
            startDate = moment().format('YYYY-MM-DD');
            endDate = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
        }

        const filterObject = {
            city,
            term,
            style,
            startDate,
            endDate,
        };

        filterObject.description = JSON.stringify(filterObject);
        eventService.logCustomEvent('desktop_search_filters', filterObject);

        const newQuery = { ...query };

        newQuery.city = city;
        if (term !== defaultTermText) {
            newQuery.term = term;
        }
        if (style !== defaultStyleText) {
            newQuery.style = style;
        }

        if (buildingParam || !_.isEmpty(buildingParam)) {
            newQuery.building = buildingParam;
        } else {
            delete newQuery.building;
        }

        if (selectedCity?.name !== city) {
            delete newQuery.building;
        }

        if (startDate) {
            newQuery.startDate = moment(startDate).format('YYYY-MM-DD');
        }
        if (endDate) {
            newQuery.endDate = moment(endDate).format('YYYY-MM-DD');
        }

        if (pageParam) {
            delete newQuery.page;
        }

        if (city === 'Nearby') {
            if (coordinates.latitude && coordinates.longitude) {
                await searchAvailable('coordinates', coordinates.latitude, coordinates.longitude);
            } else {
                await setRoomTypesAction({});
            }
            setSelectedCityByName('Nearby');
            shallow = true;
        }

        Router.push(
            {
                pathname: '/bookings',
                query: newQuery,
            },
            undefined,
            { shallow },
        );
    }

    @boundMethod
    onSelectDatesClick() {
        const { term } = this.state;
        if (term === 'Daily' || term === defaultTermText) {
            this.setState({ showDatePicker: true });
        }
    }

    @boundMethod
    onDateRangeChange(startDate, endDate) {
        this.setState({
            dailyStartDate: moment(startDate).format('YYYY-MM-DD'),
            dailyEndDate: moment(endDate).format('YYYY-MM-DD'),
            dateChanged: true,
        });
    }

    @boundMethod
    isOutsideRangeDaily(date) {
        return moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD');
    }

    getQueryParams() {
        const { query, selectedCity, setCurrentCityAction, getCoordinates } = this.props;
        const { city, term, style, dailyStartDate, dailyEndDate } = this.state;

        const cityParam = _.get(query, 'city');
        const termParam = _.get(query, 'term');
        const styleParam = _.get(query, 'style');
        const startDateParam = _.get(query, 'startDate');
        const endDateParam = _.get(query, 'endDate');

        if (cityParam && city !== cityParam) {
            this.setState({ city: cityParam });
            if (cityParam === 'Nearby') {
                getCoordinates();
                setCurrentCityAction(cityParam);
            }
        } else if (selectedCity) {
            if (selectedCity !== 'Nearby') {
                this.setState({ city: selectedCity.name });
            } else {
                this.setState({ city: selectedCity });
            }
        }

        if (termParam && term !== termParam) {
            this.setState({ term: termParam });
            if (termParam === 'Monthly') {
                this.setState({
                    dateText: `Booking from Today`,
                    showDatePicker: false,
                    monthlyStartDate: startDateParam,
                    monthlyEndDate: endDateParam,
                });
            }
            if (termParam === 'Daily') {
                this.setState({
                    showDatePicker: true,
                });
                this.setState({
                    showDatePicker: true,
                });
                if (startDateParam) {
                    this.setState({
                        dailyStartDate: startDateParam,
                    });
                }
                if (endDateParam) {
                    this.setState({
                        dailyEndDate: endDateParam,
                    });
                }
            }
        } else if (termParam && termParam === defaultTermText) {
            if (startDateParam && dailyStartDate !== startDateParam) {
                this.setState({ dailyStartDate: startDateParam, showDatePicker: true });
            }
            if (endDateParam && dailyEndDate !== endDateParam) {
                this.setState({ dailyEndDate: endDateParam, showDatePicker: true });
            }
        }
        if (styleParam && style !== styleParam) {
            this.setState({ style: styleParam });
        }
    }

    buildCityOptions() {
        const { cities } = this.props;

        const sortedCities = _.sortBy(cities, ['name']);
        return _.map(sortedCities, (_city) => {
            const city = _city;
            city.label = city.name;
            city.value = city.name;
            return city;
        });
    }

    getDefaultOptions(queryParam, Options) {
        let defaultOptions = {};
        _.forEach(Options, (options) => {
            if (options.value === queryParam) {
                defaultOptions = options;
            }
        });
        return defaultOptions;
    }

    renderCityItems() {
        const { city } = this.state;

        const cityOptions = this.buildCityOptions();
        cityOptions.unshift({ id: 'nearby', value: 'Nearby', label: 'Nearby' });
        const defaultOptions = this.getDefaultOptions(city, cityOptions);

        return (
            <SearchbarContentWrapper>
                <SearchbarHeadings>Location</SearchbarHeadings>
                <Dropdown
                    options={cityOptions}
                    onChange={(value) => this.onCityChange(value)}
                    placeholder={defaultCityText}
                    defaultSelected={defaultOptions}
                    selectId="city_dropdown"
                />
            </SearchbarContentWrapper>
        );
    }

    renderTermItems() {
        const { query } = this.props;
        const termParam = _.get(query, 'term');
        const defaultOptions = this.getDefaultOptions(termParam, termOptions);

        return (
            <SearchbarContentWrapper>
                <SearchbarHeadings>Term</SearchbarHeadings>
                <Dropdown
                    options={termOptions}
                    onChange={this.onTermChange}
                    placeholder={defaultTermText}
                    defaultSelected={defaultOptions}
                    selectId="term_dropdown"
                />
            </SearchbarContentWrapper>
        );
    }

    renderStyleItems() {
        const { query } = this.props;
        const { styleOptions } = this.state;
        const styleParam = _.get(query, 'style');
        let defaultOptions = this.getDefaultOptions(styleParam, styleOptions);

        if (this.state.term === 'Daily') {
            defaultOptions = {
                value: 'private_apartment',
                label: 'Private Apartment',
                subLabel: 'Entire apartment for yourself',
                id: 'private_apartment_select',
            };
        }
        return (
            <SearchbarContentWrapper>
                <SearchbarHeadings>Style</SearchbarHeadings>
                <Dropdown
                    options={styleOptions}
                    onChange={this.onStyleChange}
                    placeholder={defaultStyleText}
                    defaultSelected={defaultOptions}
                    selectId="style_dropdown"
                />
            </SearchbarContentWrapper>
        );
    }

    renderDateItems() {
        const { showDatePicker, dateText, dailyStartDate, dailyEndDate } = this.state;
        if (!showDatePicker) {
            return (
                <CheckInTextButton id="select_dates_button" onClick={this.onSelectDatesClick}>
                    {dateText}
                </CheckInTextButton>
            );
        }
        return (
            <HorizontalDateRangePicker
                startDate={moment(dailyStartDate)}
                endDate={moment(dailyEndDate)}
                onDateRangeChange={this.onDateRangeChange}
                isOutsideRange={this.isOutsideRangeDaily}
                dateFormat="DD MMM"
                offset
            />
        );
    }

    renderLanding() {
        const { type } = this.props;

        if (type === 'Landing' || type === 'Portfolio') {
            return (
                <Container maxWidth={800}>
                    <Row>
                        <Col size={2}>
                            <Divider size={70}>{this.renderCityItems()}</Divider>
                        </Col>
                        {publicRuntimeConfig.HOMII_FEATURE_DAILY_TERM_ENABLED && (
                            <ColLast size={2}>{this.renderTermItems()}</ColLast>
                        )}
                        <Col button size={1}>
                            <SearchButton
                                id="search_button"
                                bgcolor={this.props.theme.primaryLight}
                                startIcon={<SearchIcon />}
                                onClick={this.onSearchClick}
                            >
                                Search
                            </SearchButton>
                        </Col>
                    </Row>
                </Container>
            );
        }

        return null;
    }

    renderMobile() {
        const { type, isHome, query } = this.props;
        const { style } = this.state;
        const styleParam = _.get(query, 'style');

        if (type !== 'Mobile') {
            return null;
        }
        if (styleParam && style !== styleParam) {
            this.setState({ style: styleParam });
        }
        return (
            <Container maxWidth={800} isHome={isHome}>
                <Row>
                    <ColLast size={2}>{this.renderCityItems()}</ColLast>
                    <Col button size={1}>
                        <SearchButton
                            id="search_button"
                            bgcolor={this.props.theme.primaryLight}
                            startIcon={<SearchIcon />}
                            onClick={this.onSearchClick}
                        >
                            Search
                        </SearchButton>
                    </Col>
                </Row>
            </Container>
        );
    }

    renderBookings() {
        const { type, query } = this.props;
        const { city, term, style, tempSelection, dateChanged } = this.state;

        if (type !== 'Bookings') {
            return null;
        }

        let buttonText = 'Find a Space';
        let startIcon = null;

        const cityParam = _.get(query, 'city');

        if (
            city !== cityParam ||
            (term !== defaultTermText && _.get(tempSelection, 'selectedTerm') !== term) ||
            (style !== defaultStyleText && _.get(tempSelection, 'selectedStyle') !== style) ||
            dateChanged
        ) {
            buttonText = 'Search';
            startIcon = <SearchIcon />;
        }

        return (
            <Container>
                <Row>
                    <Col size={2}>
                        <Divider size={70}>{this.renderCityItems()}</Divider>
                    </Col>
                    {publicRuntimeConfig.HOMII_FEATURE_DAILY_TERM_ENABLED && (
                        <Col size={2}>
                            <Divider size={70}>{this.renderTermItems()}</Divider>
                        </Col>
                    )}
                    <Col size={2}>
                        <Divider size={70}>{this.renderStyleItems()}</Divider>
                    </Col>
                    <ColLast size={2}>
                        <CheckInHeading>Check-in / Check-out</CheckInHeading>
                        {this.renderDateItems()}
                    </ColLast>
                    <Col button size={1}>
                        <SearchButton
                            id="find_space_button_bar"
                            bgcolor={this.props.theme.primaryLight}
                            onClick={this.onFindaSpaceClick}
                            startIcon={startIcon}
                        >
                            {buttonText}
                        </SearchButton>
                    </Col>
                </Row>
            </Container>
        );
    }

    render() {
        return (
            <>
                {this.renderLanding()}
                {this.renderBookings()}
                {this.renderMobile()}
            </>
        );
    }
}

const SearchbarContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
`;

const Container = styled.div`
    position: relative;
    z-index: 1;
    background: ${(props) => props.theme.black};
    max-width: ${(props) => props.maxWidth}px;
    margin-top: ${(props) => (props.isHome ? -35 : 0)}px;
    margin: 0 ${(props) => props.marginHorizontal}px;
    text-align: left;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    height: 80px;
    margin: 0px auto;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const Col = styled.div`
    display: flex;
    flex-direction: column;
    flex: ${(props) => props.size};
    height: 80px;
    justify-content: center;
    &:hover {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-bottom: 2px solid ${(props) => (props.button ? 'none' : props.theme.primaryLight)};
    }
`;

const ColLast = styled(Col)`
    align-items: flex-start;
    padding-left: 20px;
`;

const Divider = styled.div`
    display: flex;
    border-right: solid ${(props) => props.theme.black} 1px;
    height: ${(props) => props.size}%;
    align-items: center;
    padding-left: 20px;
`;

const SearchbarHeadings = styled.span`
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.015em;
    margin-top: 10px;
    margin-bottom: -10px;
    margin-left: 1px;
    color: ${(props) => props.theme.searchBarHeadings};
`;

const CheckInHeading = styled(SearchbarHeadings)`
    margin-top: 0px;
    margin-bottom: 0px;
`;

const CheckInTextButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 15.5px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-top: -2px;
    color: ${(props) => props.theme.placeholderSearchText};
    &:focus {
        outline: 0;
        color: black;
    }
    &:hover {
        cursor: pointer;
    }
`;

const SearchButton = styled(Button)`
    background-color: ${(props) => props.bgcolor};
    flex: 1;
    color: ${(props) => props.theme.searchButtonText};
    padding: 7px 14px;
    &:hover {
        background-color: ${(props) => props.bgcolor};
    }
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
`;

export default withTheme(SearchBar);
