import { setCoordinatesAction } from './geolocation.reducer';

function getCoordinatesFromBrowser() {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });
}

export async function getCoordinates() {
    return async (dispatch) => {
        const position = await getCoordinatesFromBrowser();
        const { latitude, longitude } = position.coords;
        dispatch(
            setCoordinatesAction({
                latitude,
                longitude,
                isBrowser: true,
            }),
        );
    };
}

export async function setCoordinates(latitude, longitude) {
    return async (dispatch) => {
        dispatch(
            setCoordinatesAction({
                latitude,
                longitude,
                isBrowser: false,
            }),
        );
    };
}
